import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstname: '',
  lastname: '',
  email: '',
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginHandler: (state, action) => {
      state.firstname = action.payload.user.firstname;
      state.lastname = action.payload.user.lastname;
      state.email = action.payload.user.email;
      state.isLoggedIn = true;
      localStorage.setItem('token', action.payload.token);
    },

    logoutHandler: (state, action) => {
      localStorage.removeItem('token');
      Object.assign(state, initialState);
    },

    updateCustomer: (state, action) => {
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.isLoggedIn = true;
    },
  },
});

export const { loginHandler } = userSlice.actions;
export const { logoutHandler } = userSlice.actions;
export const { updateCustomer } = userSlice.actions;

// Selectors

export const getUser = (state) => state.user;

export const getIsLoggedIn = (state) => state.user.isLoggedIn;

export default userSlice.reducer;
